function hideSearchSidebar() {
    const closeBtn = document.getElementById('js-close-filters'),
        cancelBtn = document.getElementById('js-cancel-filters');
    if (closeBtn) {
        closeBtn.addEventListener('click', () => {
            closeSearchSidebar();
        });
    }

    if (cancelBtn) {
        cancelBtn.addEventListener('click', () => {
            closeSearchSidebar();
        });
    }
}

function closeSearchSidebar() {
    const sidebar = document.getElementById('js-searchresults-sidebar'),
        filtersHeader = document.getElementById('js-filters-header');
    filtersHeader.classList.remove('fade-in-active');
    filtersHeader.classList.add('fade-out');
    setTimeout(() => {
        sidebar.classList.add('slide-out-top');
        sidebar.classList.remove('slide-in-top-active');
    }, 100);
    setTimeout(() => {
        sidebar.classList.remove('slide-out-top');
        filtersHeader.classList.remove('fade-out');
    }, 401);
}

function renderStateFilter() {
    const jsStatesList = document.getElementById('js-states-list');

    if (jsStatesList !== undefined && jsStatesList !== null) {
        const inputs = jsStatesList.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', event => {
                const eventValue = event.target.value;
                if (isMobile()) {
                    const townItems = document.getElementById('js-towns-list').getElementsByTagName('li');
                    let availableTowns = 0;

                    for (let index = 0; index < townItems.length; index++) {
                        const element = townItems[index];
                        element.className = 'mb-3 mb-md-2';
                        const labelValue = element.getElementsByTagName('label')[0].getAttribute('for');
                        if (labelValue.indexOf(eventValue) > -1) {
                            if (availableTowns > 3) {
                                element.classList.add('js-toggle');
                                element.classList.add('d-none');                                
                            }
                            availableTowns += 1;
                        } else {
                            element.classList.add('d-none');
                        }
                    }
                }
                let newSearchString = '';
                if (event.target.checked === true) {
                    if (window.location.search.indexOf('q=') > -1) {
                        const stateAndFiltersAfter = window.location.search.substring(window.location.search.indexOf('q=')).split('&');
                        newSearchString = '?' + stateAndFiltersAfter.find(x => x.includes('q='));
                    }
                    if (window.location.search.indexOf('provincie=') > -1) {
                        const stateAndFiltersAfter = window.location.search.substring(window.location.search.indexOf('provincie=')).split('&');
                        const previousState = stateAndFiltersAfter.find(x => x.includes('provincie=')).replace('provincie=', '');
                        newSearchString = removeFilter('provincie', previousState, newSearchString);
                        newSearchString = addFilter('provincie', eventValue, newSearchString);

                        if (window.location.search.substring(window.location.search.indexOf('plaats=')).length > 0) {
                            newSearchString = removeSpecificFilterFromUrl(newSearchString, 'plaats')
                        }
                    } else {
                        newSearchString = addFilter('provincie', eventValue, newSearchString);
                    }
                } else {
                    newSearchString = removeFilter('provincie', eventValue);
                }
                fireSearch(newSearchString);
            });
        }
    }
}

function renderTownsFilter() {
    const jsTownsList = document.getElementById('js-towns-list');
    if (jsTownsList !== undefined && jsTownsList !== null) {
        const inputs = jsTownsList.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', event => {
                const eventId = event.target.id;
                const eventValue = event.target.value.replaceAll(' ','_');
                const state = eventId.replace('_' + eventValue, '');
                let newSearchString = '';
                if (event.target.checked === true) {
                    if (window.location.search.indexOf('provincie=') === -1) {
                        newSearchString = addFilter('plaats', eventValue, window.location.search, 'provincie', state);
                    } else {
                        newSearchString = addFilter('plaats', eventValue, window.location.search);
                    }
                } else {
                    newSearchString = removeFilter('plaats', eventValue, window.location.search);
                }

                fireSearch(newSearchString);
            });
        }
    }
}

function renderTypeFilter() {
    const jsTypeList = document.getElementById('js-types-list');
    if (jsTypeList !== undefined && jsTypeList !== null) {
        const inputs = jsTypeList.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', event => {
                const eventId = event.target.id;
                const eventValue = event.target.value.replaceAll(' ','_');
                let newSearchString = '';
                if (event.target.checked === true) {
                    newSearchString = addFilter('type', eventValue, window.location.search);
                } else {
                    newSearchString = removeFilter('type', eventValue, window.location.search);
                }
                fireSearch(newSearchString);
            });
        }
    }
}

function renderFacilityFilter() {
    const jsFacilityList = document.getElementById('js-facilities-list');
    if (jsFacilityList !== undefined && jsFacilityList !== null) {
        const inputs = jsFacilityList.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', event => {
                const eventId = event.target.id;
                const eventValue = event.target.value.replaceAll(' ','_');
                let newSearchString = '';
                if (event.target.checked === true) {
                    newSearchString = addFilter('voorziening', eventValue, window.location.search);
                } else {
                    newSearchString = removeFilter('voorziening', eventValue, window.location.search);
                }
                fireSearch(newSearchString);
            });
        }
    }
}

function renderCategoriesNewsFilter() {
    const jsTypeList = document.getElementById('js-categoriesnews-list');
    if (jsTypeList !== undefined && jsTypeList !== null) {
        const inputs = jsTypeList.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('click', event => {
                const eventValue = event.target.value.replaceAll(' ','_');
                let newSearchString = '';
                if (event.target.checked === true) {
                    newSearchString = addFilter('categorie', eventValue, window.location.search);
                } else {
                    newSearchString = removeFilter('categorie', eventValue, window.location.search);
                }
                fireSearch(newSearchString);
            });
        }
    }
}

function removeFilter(type, value, searchString = '', immediateRemove = false) {
    const path = window.location.origin + window.location.pathname;
    if (type.toLowerCase() === 'provincie') {
        const params = new URLSearchParams(window.location.search);
        let newUrl = '';
        params.delete('plaats')
        params.delete(type.toLowerCase())
        
        if (params.toString() !== '') {
            newUrl = path + '?' + params.toString();
        } else {
            newUrl = path;
        }

        if (isMobile()) {
            window.history.pushState('', document.title, newUrl);
        } else {
            window.location = newUrl;
        }

    } else {
        if (searchString === '') {
            searchString = window.location.search;
        }
        if (searchString !== '') {
            if (type == 'q') {
                value = value.replaceAll(' ', '+');
            } else {
                value = value.replaceAll(' ', '_');
                value = encodeURIComponent(value);
                value = value.replace('%2F', '/');
            }
            const stringToRemove = type.toLowerCase() + '=' + value;
            const isFirstButNotLastFilter = '?' + stringToRemove + '&';
            if (window.location.search.indexOf(isFirstButNotLastFilter) > -1) {
                searchString = searchString.replace(isFirstButNotLastFilter, '?');
            } else {
                searchString = searchString.replace('&' + stringToRemove, '');
                searchString = searchString.replace('?' + stringToRemove, '');
            }
            if (searchString === '?') {
                searchString = '';
            }
            if (immediateRemove) {
                if (isMobile()) {
                    window.history.pushState('', document.title, path + searchString);
                } else {
                    window.location = path + searchString;
                }
            }
        }
    }
    return searchString;
}

function addFilter(type, value, searchString = '', secondType = '', secondValue = '') {
    let prefix = '';
    const path = window.location.origin + window.location.pathname;
    if (searchString !== '') {
        prefix = '&';
    } else {
        prefix = '?';
    }
    let filterToAdd = prefix + type + '=' + value;
    if (secondType !== '' && secondValue !== '') {
        filterToAdd = filterToAdd + '&' + secondType + '=' + secondValue;
    }
    searchString = searchString + filterToAdd;
    return searchString;
}

function fireSearch(newSearchString) {
    const path = window.location.origin + window.location.pathname;
    const newUrl = path + newSearchString;

    if (isMobile()) {
        window.history.pushState('', document.title, newUrl);
    } else {
        window.location = newUrl;
    }
}

function removeSpecificFilterFromUrl(url, parameter) {
    //prefer to use l.search if you have a location/link object
    const urlParts = url.split('?');
    if (urlParts.length >= 2) {

        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlParts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

function addEventReadMore() {
    const readMore = document.getElementsByClassName('js-readmore');
    for (let i = 0; i < readMore.length; i++) {
        readMore[i].addEventListener('click', function () {
            toggleReadMore(this);
        }, false);

    }
}

function toggleReadMore(element) {
    const list = document.getElementById(element.getAttribute('data-listid')).getElementsByTagName("li");
    if (element.classList.contains('js-less')) {
        element.innerText = "Toon meer"
    } else {
        element.innerText = "Toon minder"
    }
    element.classList.toggle('js-less');
    for (let i = 0; i < list.length; i++) {
        if (list[i].classList.contains('js-toggle')) {
            list[i].classList.toggle("d-none");
        }
    }
}

function addRemoveFilterEvents() {
    const filters = document.getElementsByClassName('js-removefilter');
    for (let i = 0; i < filters.length; i++) {
        const filterType = filters[i].getAttribute('data-filtertype');
        const filterName = filters[i].getAttribute('data-filtername');
        filters[i].addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            removeFilter(filterType, filterName, window.location.search, true)
        }, false);
    }
}

function forceInputToLowercase() {
    const input = document.getElementById('q');
    if (input !== undefined && input !== null) {
        input.addEventListener('keyup', function(e) {
            input.value = input.value.toLowerCase();
        });
    }
}

function isMobile() {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
}

function addFilterMobile() {
    const applyFilters = document.getElementById('js-apply-filters');
    if (applyFilters !== undefined && applyFilters !== null) {
        applyFilters.addEventListener('click', function (e) {
            e.preventDefault();
            window.location.reload();
        });
    }
}

hideSearchSidebar();
renderStateFilter();
renderTownsFilter();
renderTypeFilter();
renderFacilityFilter();
renderCategoriesNewsFilter();
addEventReadMore();
addRemoveFilterEvents();
forceInputToLowercase();
addFilterMobile();