import './vendor';
import debounce from './helpers';

import './navigation';
import './slider';
import { removeClassHeaderMsg } from './header';
import './content';
import './collapses';
import './search';
import './searchresults';
import './filters';
import './umbracoforms';
import { addClassOnTop } from './footer';

// Actions on window resize
window.addEventListener('resize', debounce(() => {
  removeClassHeaderMsg();
}, 100));

// Actions on window scroll
window.addEventListener('scroll', debounce(() => {
  addClassOnTop();
}, 50));

const urlParams = new URLSearchParams(window.location.search);
const gotoParam = urlParams.get('goto');

if (gotoParam) {
  if (document.getElementById(gotoParam) !== undefined) {
    window.scrollTo(0, (document.getElementById(gotoParam).getBoundingClientRect().top + window.scrollY) - (window.innerHeight / 5))
  }
}
