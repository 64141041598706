function goToSubscribeForm() {
  const btn = document.getElementById('js-go-subscribe-form');

  if (btn) {
    btn.addEventListener('click', () => {
      const anchor = document.querySelector('#inschrijf-formulier').getBoundingClientRect().top,
            navElemHeight = document.getElementById('js-navigation').offsetHeight;

      window.scrollTo({
        top: anchor - navElemHeight
      });
    });
  }
}

function toggleExpertises() {
  const btn = document.getElementById('js-expertises');

  if (btn) {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      console.log(btn.parentElement);
    });
  }
}

function toggleList(id, type) {
  const btn = document.getElementById(id);

  if (btn) {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const ulElement = btn.parentElement.parentElement;

      if (ulElement) {
        const liItems = ulElement.getElementsByClassName('align-items-center')
        for (let index = 5; index < liItems.length; index++) {
          const element = liItems[index];
          if (element.classList.contains('d-none')) {
            element.classList.remove('d-none');
            btn.innerHTML = 'Toon minder ' + type;
          } else {
            element.classList.add('d-none');
            btn.innerHTML = 'Toon meer ' + type;
          }
        }
      }
    });
  }
}

goToSubscribeForm();
toggleList('js-facilities', 'voorzieningen');
toggleList('js-expertises', 'expertises');