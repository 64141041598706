function footerScrollToTop() {
  const backToTopBtn = document.getElementById('js-back-to-top');

  if (backToTopBtn) {
    backToTopBtn.addEventListener('click', () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
}

footerScrollToTop();

function addClassOnTop() {
  const backToTopBtn = document.getElementById('js-back-to-top');

  if (backToTopBtn) {
    if (window.pageYOffset <= 50) {
      backToTopBtn.classList.add('is-hidden');
    } else {
      backToTopBtn.classList.remove('is-hidden');
    }
  }
}

export {
  addClassOnTop
}
