function hideHeaderMsg() {
  const hideBtn = document.getElementById('js-hide-header-msg'),
        headerMsg = document.getElementById('js-header-msg');

  if (hideBtn) {
    hideBtn.addEventListener('click', () => {
      headerMsg.classList.add('scale-out-br');
      setTimeout(() => {
        headerMsg.classList.add('d-none');
        hideBtn.setAttribute('aria-expanded', 'false');
        headerMsg.classList.remove('scale-out-br');
      }, 200);
    });
  }
}

hideHeaderMsg();

function removeClassHeaderMsg() {
  const hideBtn = document.getElementById('js-hide-header-msg'),
        headerMsg = document.getElementById('js-header-msg'),
        mediaQuery = window.matchMedia('(min-width: 768px)');

  if (headerMsg) {
    if (mediaQuery.matches && headerMsg.matches('.d-none')) {
      headerMsg.classList.remove('d-none');
      hideBtn.setAttribute('aria-expanded', 'true');
    }
  }
}

export {
  removeClassHeaderMsg
}
