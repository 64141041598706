function showSearchSidebar() {
  const btn = document.getElementById('js-search-filters-btn'),
        sidebar = document.getElementById('js-searchresults-sidebar'),
        filtersHeader = document.getElementById('js-filters-header');
  if (btn) {
    btn.addEventListener('click', () => {
      setTimeout(() => {
        window.scrollTo(0,0);
        sidebar.classList.add('slide-in-top');
      }, 1);
      setTimeout(() => {
        sidebar.classList.add('slide-in-top-active');
        sidebar.classList.remove('slide-in-top');
        filtersHeader.classList.add('fade-in');
      }, 400);
      setTimeout(() => {
        filtersHeader.classList.add('fade-in-active');
        filtersHeader.classList.remove('fade-in');
      }, 501);
    });
  }
}

function AddLoadMoreEventGeneralSearch() {
  const obj = document.getElementById('js-show-more-btn');
  if (obj !== undefined && obj !== null) {
    obj.addEventListener('click', function (e) {
      e.stopImmediatePropagation();
      const params = new URLSearchParams(window.location.search);
      let more = Number(params.get('more')) + 1;
      const originalUrl = window.location.origin + window.location.pathname + window.location.search;
      window.history.pushState('', '', originalUrl + '?more=' + more);
      LoadGeneralSearchResults(obj)
    }, false);
  }
}

function LoadGeneralSearchResults(button, amount) 
{
    const results = document.getElementsByClassName('searchresults__result');
    let items = button.getAttribute('data-items');
    const more = amount !== undefined ? amount : button.getAttribute('data-more');
    items = Number(items) + Number(more);
    button.setAttribute('data-items', items);
    
    if (items > results.length) 
    {
        items = results.length;
        button.classList.add('d-none');
    }
    
    for (let i = 0; i < items; i++) {
        results[i].classList.remove('d-none');
    }    
}

function ShowMoreResultsBasedOnQuerystring() {
  const params = new URLSearchParams(window.location.search);
  const more = Number(params.get('more'));
  const obj = document.getElementById('js-show-more-btn');
  if (obj !== undefined && obj !== null) {
    const buttonMore = Number(obj.getAttribute('data-more'));
    if (more !== null) {
      const amount = buttonMore * more;
      LoadGeneralSearchResults(obj, amount)
    }
  }
}

showSearchSidebar();
AddLoadMoreEventGeneralSearch();
ShowMoreResultsBasedOnQuerystring();