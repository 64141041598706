import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function contentSlider() {
  const element = document.getElementById('js-content-slider');
  if (element) {
    return tns({
      container: '#js-content-slider',
      items: 1,
      arrowKeys: true,
      speed: 200,
      autoplay: true,
      autoplayTimeout: 15000,
      autoplayHoverPause: true,
      autoplayButtonOutput: false,
      nav: true,
      onInit: () => {
        const tnsNav = document.querySelector('.tns-nav'),
              buttons = document.querySelectorAll('.tns-nav button');
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].innerHTML = i + 1;
        }
        tnsNav.insertAdjacentHTML('beforeend', ' ' + '<span class="slider__slider-status">' + '/' + ' ' + buttons.length + "</span>");
      }
    });
  }
}

function teamSlider() {
  const element = document.getElementById('js-team-slider');
  if (element) {
    return tns({
      container: '#js-team-slider',
      items: 1,
      arrowKeys: true,
      speed: 200,
      autoplay: false,
      nav: true,
      navPosition: 'bottom',
      onInit: () => {
        const tnsNav = document.querySelector('.tns-nav'),
              buttons = document.querySelectorAll('.tns-nav button');
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].innerHTML = i + 1;
        }
        tnsNav.insertAdjacentHTML('beforeend', ' ' + '<span class="slider__slider-status">' + '/' + ' ' + buttons.length + "</span>");      }
    });
  }
}

contentSlider();
teamSlider();
