function modifyCheckboxHtml() {
  const elements = document.querySelectorAll('.umbraco-forms-field.checkbox');

  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      const parentElements = elements[i],
            childElements = parentElements.getElementsByClassName('umbraco-forms-field-wrapper'),
            labelElements = parentElements.getElementsByTagName('label');
      labelElements[0].removeAttribute('class');
      childElements[0].insertBefore(labelElements[0], childElements[0].children[1]);
    }

  }
}

function modifyDataconsentHtml() {
  const parentElement = document.querySelector('.dataconsent .umbraco-forms-field-wrapper');

  if (parentElement) {
    const childElement = parentElement.querySelector('input[type=hidden]'),
          spanElement = parentElement.querySelector('span');
    parentElement.insertBefore(childElement, spanElement);
  }
}

modifyCheckboxHtml();
modifyDataconsentHtml();
