function closeNavCollapse() {
  const navSearchToggler = document.getElementById('js-search-toggler'),
        navCollapse = document.getElementById('js-navbar-collapse');

  if (navSearchToggler) {
    const bsCollapse = new bootstrap.Collapse(navCollapse, {
      toggle: false
    });

    navSearchToggler.addEventListener('click', () => {
      if (navCollapse.classList.contains('show')) {
        bsCollapse.toggle();
      }
    });
  }
}

closeNavCollapse();
